import Parse from "parse";












export class UserBlacklist extends Parse.Object {
  constructor(data) {
    super("RideSharingUserBlacklist", data );
  }
}

Parse.Object.registerSubclass("RideSharingUserBlacklist", UserBlacklist);
