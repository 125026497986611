const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserSettingsRoute.tsx";import React from "react";
import {
  getUserProfile,
  OfflineMessage,
  useIsOnline,
  UserSettings,
  UserSettingsLock,
} from "..";

export function UserSettingsRoute() {
  const isOnline = useIsOnline();
  const isSimple = getUserProfile().accessibilitySupervisorEnabled;
  const [isLocked, setLocked] = React.useState(true);

  if (!isOnline) {
    return React.createElement(OfflineMessage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} );
  }

  if (isSimple && isLocked) {
    return (
      React.createElement(UserSettingsLock, {
        onUnlock: () => {
          setLocked(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      )
    );
  }

  return React.createElement(UserSettings, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} );
}
