const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RoutesMap.tsx";import React from "react";
import { Map, MapPlaces, MapRoutes, } from "..";









export function RoutesMap({
  routes,
  route,
  places = [],
  placesSelected = [],
  onPlaceSelection,
}) {
  return (
    React.createElement(Map, { width: "100%", height: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , React.createElement(MapPlaces, {
        places: places,
        selection: placesSelected,
        onSelection: onPlaceSelection, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      )
      , React.createElement(MapRoutes, { routes: routes, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
    )
  );
}
