const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\SearchResultList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Avatar, Button, List, Modal } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

import React from "react";
import { MetaIcon } from "..";
import { $kommuter } from "../exports";
























































export const SearchResultList = observer(function SearchResultList({
  result,
  loading,
  startLocation,
  endLocation,
  date,
  weekday,
  time,
}) {
  return (
    React.createElement(List, {
      loading: loading,
      dataSource: result,
      rowKey: "key",
      renderItem: (item) => {
        switch (item.type) {
          case "offer":
            return (
              React.createElement(OfferItem, {
                item: item,
                startLocation: startLocation,
                endLocation: endLocation,
                date: date,
                weekday: weekday,
                time: time, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
              )
            );
          case "public-transport":
            return React.createElement(PublicTransportItem, { item: item, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} );
          case "other-website":
            return React.createElement(WebsiteItem, { item: item, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} );
          case "other-phone":
            return React.createElement(PhoneItem, { item: item, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} );
          default:
            return null;
        }
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
    )
  );
});

const ListIcon = React.memo(function ListIcon({ icon }) {
  return (
    React.createElement('div', {
      style: { width: "auto", height: 48, lineHeight: "48px", fontSize: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}

      , React.createElement(Icon, { icon: icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} )
    )
  );
});

const OfferItem = observer






(function OfferItem({
  item,
  startLocation,
  endLocation,
  date,
  weekday,
  time,
}) {
  const t = useTranslation();

  const title = t("rs:offer.results.title", {
    name: item.user.name,
  });

  const description = t("rs:offer.results.description_nearby", {
    time: item.destination.time,
    postcode: item.destination.postcode,
    city: item.destination.city,
  });

  const icon = "fa:car";

  const chat = $kommuter.chat.chats.find(
    (c) => _optionalChain([c, 'access', _ => _.get, 'call', _2 => _2("offer"), 'optionalAccess', _3 => _3.id]) === item.key
  );

  const onClick = () => {
    if (chat) {
      $framework.router.navigate("/ride-sharing/messenger?chat=" + chat.id);
    } else {
      $kommuter.offers.requestOffer({
        offerId: item.key,
        startLocation,
        endLocation,
        date,
        weekday,
        time,
      });
    }
  };

  return (
    React.createElement(List.Item, {
      onClick: onClick,
      extra: 
        chat ? (
          React.createElement(Button, { children: t("rs:search.request_open"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}} )
        ) : (
          React.createElement(Button, { type: "primary", children: t("rs:search.request_create"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}} )
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}

      , React.createElement(List.Item.Meta, {
        title: title,
        description: description,
        avatar: 
          item.user.image ? (
            React.createElement(Avatar, {
              src: item.user.image,
              size: "large",
              style: {
                width: "40px",
                height: "40px",
                marginLeft: "-10px",
                marginTop: "4px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
            )
          ) : (
            React.createElement(ListIcon, { icon: icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}} )
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
      )
    )
  );
});

const PublicTransportItem = observer(
  function PublicTransportItem({ item }) {
    const line = item.lines.join(", ");
    const when = dayjs(item.departureTime).format("HH:mm");

    const title = `${line} um ${when}`;
    const description = item.stations.join(" → ");

    const icon = "fa:bus";

    return (
      React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 211}}
        , React.createElement(List.Item.Meta, {
          avatar: React.createElement(ListIcon, { icon: icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 213}} ),
          title: title,
          description: description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 212}}
        )
      )
    );
  }
);

const PhoneItem = observer(function PhoneItem({ item }) {
  const t = useTranslation();

  return (
    React.createElement(List.Item, {
      onClick: () => {
        window.open("tel:" + item.phone);
      },
      extra: 
        React.createElement(Button, {
          icon: React.createElement(MetaIcon, { icon: "anrufen_waehlen", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}} ),
          children: t("rs:search.results.cap_offers_call_action"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 226}}

      , React.createElement(List.Item.Meta, {
        avatar: React.createElement(ListIcon, { icon: "fa:phone-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}} ),
        title: item.name,
        description: item.phone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 237}}
      )
    )
  );
});

const WebsiteItem = observer(function WebsiteItem({
  item,
}) {
  const t = useTranslation();

  return (
    React.createElement(List.Item, {
      onClick: () => {
        Modal.confirm({
          title: t("rs:app.open_external_confirm_title"),
          content: t("rs:app.open_external_confirm_description"),
          okText: t("rs:app.open_external_confirm_ok"),
          onOk() {
            window.open(item.website);
          },
        });
      },
      extra: 
        React.createElement(Button, {
          icon: React.createElement(MetaIcon, { icon: "googeln", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}} ),
          children: t("rs:search.results.cap_offers_web_action"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 264}}
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}

      , React.createElement(List.Item.Meta, {
        avatar: React.createElement(ListIcon, { icon: "fa:globe", __self: this, __source: {fileName: _jsxFileName, lineNumber: 271}} ),
        title: item.name,
        description: new URL(item.website).host, __self: this, __source: {fileName: _jsxFileName, lineNumber: 270}}
      )
    )
  );
});
