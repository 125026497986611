const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideSearchResultsDefault.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import React from "react";
import {
  getUserProfile,
  ListContainer,
  MetaIcon,
  PublicTransportList,
  RideOfferResultList,
  TaxiInfoList,
  TitleContainer,
  usePublicTransportRoute,
  useRideSearch,
  useRideSearchStart,
} from "..";

export function RideSearchResultsDefault({}) {
  const t = useTranslation();

  const [start] = useRideSearchStart();
  const [pt] = usePublicTransportRoute();
  const offers = useRideSearch();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        , React.createElement(MetaIcon, { icon: "auto3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
        , t("rs:search.ride_offers")
      )
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , React.createElement(RideOfferResultList, { data: offers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
      )

      , !_optionalChain([getUserProfile, 'call', _ => _(), 'optionalAccess', _2 => _2.publicTransportDisabled]) && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
            , React.createElement(MetaIcon, { icon: "haltestelle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
            , t("rs:search.public_transport_offers")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
            , React.createElement(PublicTransportList, { data: pt, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
          )
        )
      )

      , !_optionalChain([getUserProfile, 'call', _3 => _3(), 'optionalAccess', _4 => _4.otherTransportsDisabled]) && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
            , React.createElement(MetaIcon, { icon: "taxi", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
            , t("rs:search.cap_offers")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , React.createElement(TaxiInfoList, { location: start, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
          )
        )
      )
    )
  );
}
