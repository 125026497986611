import Parse from "parse";



















export class Place extends Parse.Object {
  constructor(data) {
    super("RideSharingPlace", data );
  }
}

Parse.Object.registerSubclass("RideSharingPlace", Place);
