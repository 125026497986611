const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideOfferRequest.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework, useTranslation } from "@opendash/core";
import { translateSync } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import distance from "@turf/distance";
import { Button, Descriptions, Drawer, Image, List, Spin, message } from "antd";
import dayjs from "dayjs";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import React from "react";
import {
  $kommuter,
  Chat,
  Offer,

  getProfileImage,
  getUserProfile,
} from "..";

const buttonStyle = {
  width: "100%",
};

class RideOfferRequestState {
   __init() {this.valid = false}
   __init2() {this.visible = false}

   __init3() {this.offerId = null}
   __init4() {this.startLocation = null}
   __init5() {this.endLocation = null}
  
  
  

   __init6() {this.step = "start"}

   __init7() {this.offer = undefined}
   __init8() {this.offerLoading = true}

   __init9() {this.places = []}
   __init10() {this.placesDistances = {}}
   __init11() {this.placesLoading = false}

  
  

  constructor(payload) {;RideOfferRequestState.prototype.__init.call(this);RideOfferRequestState.prototype.__init2.call(this);RideOfferRequestState.prototype.__init3.call(this);RideOfferRequestState.prototype.__init4.call(this);RideOfferRequestState.prototype.__init5.call(this);RideOfferRequestState.prototype.__init6.call(this);RideOfferRequestState.prototype.__init7.call(this);RideOfferRequestState.prototype.__init8.call(this);RideOfferRequestState.prototype.__init9.call(this);RideOfferRequestState.prototype.__init10.call(this);RideOfferRequestState.prototype.__init11.call(this);
    if (payload) {
      try {
        const data = $kommuter.offers.parseOfferPayload(payload);

        this.valid = true;
        this.visible = true;

        this.offerId = data.offerId;
        this.startLocation = data.startLocation;
        this.endLocation = data.endLocation;
        this.time = data.time;
        this.weekday = data.weekday;
        this.date = data.date;

        makeAutoObservable(this);

        this.init();
      } catch (error) {
        console.error(error);

        this.valid = false;
      }
    } else {
      this.valid = true;
      this.visible = false;
    }
  }

  get offerer() {
    return _optionalChain([this, 'access', _ => _.offer, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("user")]);
  }

  get offererProfile() {
    return getUserProfile(this.offerer);
  }

   async init() {
    if (!this.offerId) return;

    const offer = await new Parse.Query(Offer)
      .include("user")
      // @ts-ignore
      .include("user.rsProfile")
      .get(this.offerId);

    runInAction(() => {
      this.offer = offer;
      this.offerLoading = false;
    });

    this.fetchPlaces();
  }

   async fetchPlaces() {
    this.places = [];
    this.placesDistances = {};
    this.placesLoading = true;

    if (!this.offer) return;

    const km = Math.ceil(_optionalChain([getUserProfile, 'call', _4 => _4(), 'optionalAccess', _5 => _5.searchBuffer]) || 2);

    const query = this.offer.get("places").query() ;

    if (this.step === "start" && this.startLocation) {
      query.withinKilometers("location", this.startLocation, km);
    }

    if (this.step === "end" && this.endLocation) {
      query.withinKilometers("location", this.endLocation, km);
    }

    query.equalTo("enabled", true);
    query.limit(5);

    const places = await query.find();

    const placesDistances = {};

    for (const palce of places) {
      const a = this.step === "start" ? this.startLocation : this.endLocation;
      const b = palce.get("location");

      if (a) {
        placesDistances[palce.id] = Math.round(
          distance([a.latitude, a.longitude], [b.latitude, b.longitude], {
            units: "meters",
          })
        );
      } else {
        placesDistances[palce.id] = null;
      }
    }

    runInAction(() => {
      this.places = places;
      this.placesDistances = placesDistances;
      this.placesLoading = false;
    });
  }

  setStart(place) {
    this.start = place;
    this.step = "end";

    this.fetchPlaces();
  }

  setEnd(place) {
    this.end = place;
    this.step = "overview";

    this.fetchPlaces();
  }

  close() {
    $kommuter.offers.requestOffer(null);
  }

  getDrawerProps() {
    const user = this.offerer;
    const avatar = getProfileImage(user);

    if (!this.valid || !this.visible) {
      return {
        open: false,
        placement: "bottom" ,
      };
    }

    return {
      open: true,
      placement: "bottom" ,
      onClose: () => {
        this.close();
      },
      headerStyle: {
        padding: "8px 20px",
      },
      height: this.end ? 350 : 650,
      title: (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
          , (avatar || null) && (
            React.createElement('div', {
              style: {
                display: "inline-block",
                height: 40,
                float: "left",
                marginRight: 10,
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}

              , avatar ? (
                React.createElement(Image, {
                  width: 40,
                  height: 40,
                  src: avatar,
                  style: { borderRadius: "50%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
                )
              ) : (
                React.createElement('div', {
                  style: {
                    fontSize: "20px",
                    width: "40px",
                    height: "40px",
                    lineHeight: "40px",
                    textAlign: "center",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}

                  , React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 216}} )
                )
              )
            )
          )

          , React.createElement('div', {
            style: { display: "inline-block", height: 40, lineHeight: "40px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 222}}

            , translateSync("rs:offer.request.title", {
              name: _optionalChain([user, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("name")]) || "Unbekannt",
            })
          )
        )
      ),
    };
  }
}

export const RideOfferRequest = observer(() => {
  const t = useTranslation();

  const payload = $kommuter.offers.requestedOfferId;

  const state = React.useMemo(
    () => new RideOfferRequestState(payload),
    [payload]
  );

  if (!state.valid || !state.visible) {
    return React.createElement(Drawer, { ...state.getDrawerProps(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 246}} );
  }

  if (state.offerLoading || state.placesLoading) {
    return (
      React.createElement(Drawer, { ...state.getDrawerProps(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
        , React.createElement('div', { style: { textAlign: "center", paddingTop: 40 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}
          , React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 253}} )
        )
      )
    );
  }

  if (state.start && state.end) {
    return (
      React.createElement(Drawer, { ...state.getDrawerProps(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 261}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 262}}, t("rs:offer.request.confirm_text"))
        , React.createElement(Descriptions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 263}}
          , _optionalChain([state, 'access', _8 => _8.offererProfile, 'optionalAccess', _9 => _9.car]) && (
            React.createElement(Descriptions.Item, {
              label: t("parse-custom:classes.RideSharingProfile.fields.car"),
              children: _optionalChain([state, 'access', _10 => _10.offererProfile, 'optionalAccess', _11 => _11.car]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
            )
          )

          , _optionalChain([state, 'access', _12 => _12.offererProfile, 'optionalAccess', _13 => _13.licenseYear]) && (
            React.createElement(Descriptions.Item, {
              label: t(
                "parse-custom:classes.RideSharingProfile.fields.licenseYear"
              ),
              children: _optionalChain([state, 'access', _14 => _14.offererProfile, 'optionalAccess', _15 => _15.licenseYear]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 272}}
            )
          )

          , _optionalChain([state, 'access', _16 => _16.offererProfile, 'optionalAccess', _17 => _17.offerAccessibilityWheelchair]) && (
            React.createElement(Descriptions.Item, {
              label: t(
                "parse-custom:classes.RideSharingProfile.fields.offerAccessibilityWheelchair"
              ),
              children: 
                _optionalChain([state, 'access', _18 => _18.offererProfile, 'optionalAccess', _19 => _19.offerAccessibilityWheelchair])
                  ? "Ja"
                  : "Nein"
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 281}}
            )
          )

          , React.createElement(Descriptions.Item, {
            label: t("rs:offer.request.start_place"),
            children: `${state.start.get("name")} (${state.start.get("type")})`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 293}}
          )

          , React.createElement(Descriptions.Item, {
            label: t("rs:offer.request.end_place"),
            children: `${state.end.get("name")} (${state.end.get("type")})`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 298}}
          )

          , React.createElement(Descriptions.Item, {
            label: t("rs:ride.date_label"),
            children: dayjs(state.date).format("DD.MM.YYYY"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}
          )

          , React.createElement(Descriptions.Item, {
            label: t("rs:ride.time_label"),
            children: state.time, __self: this, __source: {fileName: _jsxFileName, lineNumber: 308}}
          )
        )
        , React.createElement(Button, {
          type: "primary",
          style: buttonStyle,
          children: t("rs:offer.request.confirm_action"),
          onClick: () => {
            const chat = new Chat({
              offer: state.offer,
              start: state.start,
              end: state.end,
              time: state.time,
              date: state.date,
              locked: false,
              lastMessageAt: new Date(),
              lastMessageUser: Parse.User.current(),
            });
            chat.save().then(
              () => {
                // TODO
                setTimeout(() => {
                  message.success(t("rs:offer.request.success"));
                  $kommuter.offers.requestOffer(null);
                  $framework.router.navigate(
                    "/ride-sharing/messenger?chat=" + chat.id
                  );
                }, 500);
              },
              (error) => {
                message.error(t("rs:offer.request.error"));
                console.error(error);
              }
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}
        )
      )
    );
  }

  return (
    React.createElement(Drawer, { ...state.getDrawerProps(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 351}}
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 352}}
        , t(
          state.start
            ? "rs:offer.request.select_end_place"
            : "rs:offer.request.select_start_place"
        )
      )

      , React.createElement(List, {
        loading: state.placesLoading,
        dataSource: state.places,
        renderItem: (place) => (
          React.createElement(List.Item, {
            extra: 
              React.createElement(Button, {
                children: t("rs:offer.request.select_place"),
                onClick: () => {
                  if (state.start) {
                    state.setEnd(place);
                  } else {
                    state.setStart(place);
                  }
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 366}}
              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 364}}

            , React.createElement(List.Item.Meta, {
              avatar: 
                React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 380}}
                  , React.createElement('div', { style: { fontSize: 20, textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 381}}
                    , React.createElement(Icon, { icon: place.get("icon") || "fa:map-marker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 382}} )
                  )
                  , state.placesDistances[place.id] !== null && (
                    React.createElement('div', { style: { fontSize: 12, textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}
                      , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}, state.placesDistances[place.id], "m")
                    )
                  )
                  , state.placesDistances[place.id] !== null && (
                    React.createElement('div', { style: { fontSize: 10, textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 390}}, "Luftlinie"

                    )
                  )
                )
              ,
              title: 
                place.get("highlight") ? (
                  React.createElement(React.Fragment, null
                    , React.createElement(Icon, {
                      icon: "ai:star",
                      style: {
                        color: "var(--ant-primary-color)",
                        marginRight: 5,
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 399}}
                    )
                    , place.get("name")
                  )
                ) : (
                  place.get("name")
                )
              ,
              description: place.get("type"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 378}}
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 360}}
      )
      , React.createElement('p', { style: { fontSize: ".8em", textAlign: "center", paddingTop: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}
        , React.createElement(Icon, { icon: "ai:star", style: { color: "var(--ant-primary-color)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 418}} )
        , t("rs:ride.places_highlight_desc")
      )
    )
  );
});
