const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MetaIcon.tsx";import React from "react";
import styled from "styled-components";
import { getUserProfile } from "..";

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
`;

const Image = styled.img`
  height: 50px;
  width: auto;

  ${(props) =>
    props.isButton && {
      height: "1em",
      marginRight: 5,
    }}
`;






export const MetaIcon = React.memo(function MetaIcon({
  icon,
  button: isButton = false,
}) {
  const active = getUserProfile().accessibilityShowMetaIcons;

  if (!active) {
    return null;
  }

  if (!Array.isArray(icon)) {
    icon = [icon];
  }

  const icons = (Array.isArray(icon) ? icon : [icon]).map(
    (icon) => `/metaicons/${icon}.png`
  );

  if (isButton) {
    return (
      React.createElement(React.Fragment, null
        , icons.map((icon) => (
          React.createElement(Image, { key: icon, alt: icon, src: icon, isButton: isButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
        ))
      )
    );
  }

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      , icon.map((icon) => (
        React.createElement(Image, {
          key: icon,
          alt: icon,
          src: `/metaicons/${icon}.png`,
          isButton: isButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        )
      ))
    )
  );
});
