const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserProfile.tsx";import { FormGenerator, useTranslation, useUrlParam } from "@opendash/core";
import { Alert, message } from "antd";
import Parse from "parse";
import React from "react";

const CURRENT_YEAR = new Date().getFullYear();

const DEFAULT_FORM = [
  {
    key: "birthYear",
    type: "select",
    settings: {
      options: [
        {
          value: 0,
          label: "Keine Angabe",
        },
        ...Array.from(Array(60).keys()).map((v, i) => {
          const y = CURRENT_YEAR - 12 - i;

          return {
            value: y,
            label: y.toString(),
          };
        }),
      ],
    },
  },
  {
    key: "gender",
    type: "select",
    settings: {
      options: [
        {
          value: "nv",
          label: "parse-custom:classes.RideSharingProfile.fields.gender_nv",
        },
        {
          value: "female",
          label: "parse-custom:classes.RideSharingProfile.fields.gender_female",
        },
        {
          value: "male",
          label: "parse-custom:classes.RideSharingProfile.fields.gender_male",
        },
        {
          value: "other",
          label: "parse-custom:classes.RideSharingProfile.fields.gender_other",
        },
      ],
    },
  },
  {
    key: "homeDisabled",
    type: "switch",
    settings: {},
  },
];

const SEARCH_FORM = [
  {
    key: "searchBuffer",
    description:
      "parse-custom:classes.RideSharingProfile.fields.searchBuffer_description",
    type: "input.number",
    settings: {
      min: 100,
      max: 10000,
      step: 50,
    },
  },
  {
    key: "searchWomanOnly",
    type: "switch",
    settings: {},
    visible: (state) => state.gender === "female",
  },
  {
    key: "searchAccessibilityWheelchair",
    type: "switch",
    settings: {},
  },
];

const OFFER_FORM = [
  {
    key: "offerDisabled",
    type: "switch",
    settings: {},
  },
  {
    key: "offerBuffer",
    type: "input.number",
    settings: {
      min: 100,
      max: 20000,
      step: 100,
    },
    visible: (state) => !state.offerDisabled,
  },
  {
    key: "licenseYear",
    type: "input.number",
    settings: {
      min: 1900,
      max: 2022,
      step: 1,
    },
    visible: (state) => !state.offerDisabled,
  },
  {
    key: "car",
    type: "input",
    settings: {},
    visible: (state) => !state.offerDisabled,
  },
  {
    key: "offerWomanOnly",
    type: "switch",
    settings: {},
    visible: (state) => !state.offerDisabled && state.gender === "female",
  },
  {
    key: "offerAccessibilityWheelchair",
    type: "switch",
    settings: {},
    visible: (state) => !state.offerDisabled,
  },
];

const ACCESSIBILITY_FORM = [
  {
    key: "homeDisabled",
    type: "switch",
    settings: {},
  },
  {
    key: "offerDisabled",
    type: "switch",
    settings: {},
  },
  {
    key: "publicTransportDisabled",
    type: "switch",
    settings: {},
  },
  {
    key: "otherTransportsDisabled",
    type: "switch",
    settings: {},
  },
  {
    key: "accessibilityShowMetaIcons",
    type: "switch",
    settings: {},
  },
  {
    key: "accessibilityUsePredefinedSearch",
    type: "switch",
    settings: {},
  },
  {
    key: "accessibilityUsePredefinedMessages",
    type: "switch",
    settings: {},
  },
  {
    key: "accessibilitySecondSeatRequired",
    type: "switch",
    settings: {},
  },
  {
    key: "accessibilitySupervisorEnabled",
    type: "switch",
    settings: {},
  },
  {
    key: "accessibilitySupervisorPin",
    type: "input.number",
    settings: {},
    visible: (state) => state.accessibilitySupervisorEnabled,
  },
];

function copyProfile(profile) {
  profile = JSON.stringify(profile);
  profile = JSON.parse(profile);

  delete profile.objectId;
  delete profile.ACL;
  delete profile.createdAt;
  delete profile.updatedAt;
  delete profile.user;

  return profile;
}

export function UserProfile() {
  const t = useTranslation();
  const user = Parse.User.current();
  const profile = user.get("rsProfile");

  const [tab, setTab] = useUrlParam("tab", null);

  const [state, setState] = React.useState(copyProfile(profile));
  const [isUnsaved, setUnsaved] = React.useState(false);

  const updateState = React.useCallback(
    (key, value) => {
      setState((current) => ({
        ...current,
        [key]: value,
      }));
    },
    [setState]
  );

  const onSubmit = React.useCallback(() => {
    profile
      .save(state)
      .then((ok) => {
        setState(copyProfile(profile));
        setUnsaved(false);

        return user.fetchWithInclude(["tenant", "tenant.meta", "rsProfile"]);
      })
      .then(() => {
        message.success(t("rs:profile.save_success"));
        setTimeout(() => {
          //window.location.href = "/ride-sharing/profile";
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        message.error(t("rs:profile.save_error"));
      });
  }, [state, setState, setUnsaved]);

  const formConfigRaw = React.useMemo(() => {
    switch (tab) {
      case "search":
        return SEARCH_FORM;

      case "offer":
        return OFFER_FORM;

      case "accessibility":
        return ACCESSIBILITY_FORM;

      default:
        return DEFAULT_FORM;
    }
  }, [t, isUnsaved]);

  const formConfig = React.useMemo(() => {
    const prefix = "parse-custom:classes.RideSharingProfile.fields.";
    return formConfigRaw.map((field) => ({
      ...field,
      label: prefix + field.key,
      description: prefix + field.key + "_description",
    }));
  }, [formConfigRaw]);

  const submitSettings = React.useMemo(
    () => ({
      children: t("rs:profile.save_label"),
      disabled: !isUnsaved,
    }),
    [t, isUnsaved]
  );

  const onChange = React.useCallback(
    (state) => {
      if (!isUnsaved) {
        setUnsaved(true);
      }
    },
    [isUnsaved, setUnsaved]
  );

  return (
    React.createElement('div', { style: { paddingTop: 24, paddingBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 282}}
      , isUnsaved && (
        React.createElement('div', { style: { marginBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 284}}
          , React.createElement(Alert, {
            type: "info",
            message: "Du musst speichern, um die Änderungen zu übernehmen."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}}
          )
          /* <Button
            type="primary"
            style={{ float: "right" }}
            icon={<Icon icon="ai:save" />}
            children={t("profile.save_label")}
            onClick={onSubmit}
          /> */
        )
      )

      , React.createElement(FormGenerator, {
        state: state,
        updateState: updateState,
        onChange: onChange,
        elements: formConfig,
        submit: submitSettings,
        onSubmit: onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 299}}
      )
    )
  );
}
