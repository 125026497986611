import { $framework, useUserService } from "@opendash/core";
import React from "react";
import { getUserProfile, useIsOnline } from "..";

import { kommuter } from "..";

export function AppData({ children }) {
  const UserService = useUserService();
  const online = useIsOnline();

  React.useEffect(() => {
    return kommuter.init();
  }, [online]);

  React.useEffect(() => {
    kommuter.push.init(UserService);
  }, []);

  React.useEffect(() => {
    if (getUserProfile().showIntro) {
      $framework.router.navigate("/ride-sharing/intro");
    }
  }, []);

  // if (state.loading) {
  //   return null;
  // }

  // if (state.error) {
  //   return (
  //     <ErrorMessage
  //       icon="fa:exclamation-circle"
  //       title={t("rs:app.data_error_title")}
  //       message={t("rs:app.data_error_subtitle")}
  //     />
  //   );
  // }

  return children;
}
