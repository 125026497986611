const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserSettings.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { PageHeader } from "@ant-design/pro-layout";
import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, List } from "antd";
import React from "react";
import {
  ListContainer,
  UserBlacklistList,
  UserLanguageSelection,
  UserLogout,
  UserPredefinedSearch,
  UserProfile,
  UserProfileImage,
  UserQuickMessage,
  getUserProfile,
} from "..";
import { UserPasswordChange } from "./UserPasswordChange";

export function UserSettings() {
  const t = useTranslation();

  const [tab, setTab] = useUrlParam("tab", null);

  const tabs = React.useMemo(
    () => [
      {
        key: "profile",
        label: t("rs:profile.default_tab"),
        component: UserProfile,
      },
      {
        key: "search",
        label: t("rs:profile.search_tab"),
        component: UserProfile,
      },
      {
        key: "offer",
        label: t("rs:profile.offer_tab"),
        component: UserProfile,
      },
      {
        key: "accessibility",
        label: t("rs:profile.accessibility_tab"),
        component: UserProfile,
      },
      {
        key: "image",
        label: t("parse-custom:classes.RideSharingProfile.fields.image"),
        component: UserProfileImage,
      },
      {
        key: "blacklist",
        label: t("rs:user.blacklist.headline"),
        component: UserBlacklistList,
      },
      ...(getUserProfile().accessibilityUsePredefinedSearch
        ? [
            {
              key: "predefined-search",
              label: t("rs:profile.predefined_search_tab"),
              component: UserPredefinedSearch,
            },
          ]
        : []),
      ...(getUserProfile().accessibilityUsePredefinedSearch
        ? [
            {
              key: "quickmessages",
              label: t("rs:profile.predefined_message_tab"),
              component: UserQuickMessage,
            },
          ]
        : []),
      {
        key: "language",
        label: t("rs:user.language"),
        component: UserLanguageSelection,
      },
      {
        key: "password",
        label: t("rs:user.password"),
        component: UserPasswordChange,
      },
      {
        key: "logout",
        label: t("rs:user.logout.headline"),
        component: UserLogout,
      },
    ],
    [t]
  );

  const activeTab = tabs.find((t) => t.key === tab);
  const headline = _nullishCoalesce(_optionalChain([activeTab, 'optionalAccess', _ => _.label]), () => ( t("rs:profile.label")));
  const Component = _optionalChain([activeTab, 'optionalAccess', _2 => _2.component]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(PageHeader, {
        title: headline,
        onBack: !tab ? undefined : () => setTab(null), __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
      )

      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        , activeTab && React.createElement(Component, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}} )
        , !activeTab && (
          React.createElement(List, {
            dataSource: tabs,
            renderItem: (item) => (
              React.createElement(List.Item, {
                onClick: () => {
                  if (item.component) {
                    setTab(item.key);
                  }
                },
                extra: React.createElement(Button, { type: "link", icon: React.createElement(Icon, { icon: "ai:right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}

                , React.createElement(List.Item.Meta, { title: item.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}} )
              )
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
          )
        )
      )
    )
  );
}
