const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\ListEmpty.tsx";import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 24px 0;
  text-align: center;
`;

export const ListEmpty = function ({ children }) {
  return React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}, children);
};
