const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideSearchInputSimple.tsx";import { useTranslation, useUrlParam } from "@opendash/core";
import { Button } from "antd";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import {
  ListContainer,
  Search,
  TitleContainer,
  useRideSearchDate,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
} from "..";

const containerStyle = {
  paddingTop: 24,
};

const buttonStyle = {
  width: "100%",
  marginBottom: 24,
};

export function RideSearchInputSimple({}) {
  const t = useTranslation();

  const [view, setView] = useUrlParam("view", null);

  const [start, setStart] = useRideSearchStart();
  const [end, setEnd] = useRideSearchEnd();
  const [time, setTime] = useRideSearchTime();
  const [date, setDate] = useRideSearchDate();

  const query = React.useMemo(
    () =>
      new Parse.Query(Search)
        .include("start")
        .include("end")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, t("rs:search.label"))
      , React.createElement(ListContainer, { style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , result.map((search) => (
          React.createElement(Button, {
            key: search.id,
            size: "large",
            type: "primary",
            style: buttonStyle,
            // icon="retweet"
            title: t("rs:search.switch"),
            children: search.get("title"),
            onClick: (e) => {
              setStart(search.get("start"));
              setEnd(search.get("end"));
              setTime(search.get("time"));
              setDate(Date.now());

              setView("results");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          )
        ))
      )
    )
  );
}
