import Parse from "parse";






















export class Offer extends Parse.Object {
  constructor(data) {
    super("RideSharingOffer", data );
  }
}

Parse.Object.registerSubclass("RideSharingOffer", Offer);
