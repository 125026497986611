const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\LocationList.tsx";import { Icon } from "@opendash/icons";
import React from "react";
import { LinkList, } from "..";







export const LocationList = React.memo(
  ({ loading, locations, onChange }) => {
    return (
      React.createElement(LinkList, {
        compact: true,
        loading: loading,
        rows: locations.map((location) => ({
          key: location.id,
          title: location.get("title"),
          description: location.get("description"),
          avatar: (
            React.createElement(Icon, {
              icon: location.get("icon") || "fa:clock",
              style: {
                height: "48px",
                lineHeight: "48px",
                fontSize: "18px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
            )
          ),
          onClick() {
            onChange(location);
          },
        })), __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      )
    );
  }
);
