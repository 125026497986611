import Parse from "parse";


















export class OfferIndex extends Parse.Object {
  constructor(data) {
    super("RideSharingOfferIndex", data );
  }
}

Parse.Object.registerSubclass("RideSharingOfferIndex", OfferIndex);
