import { useStorage } from "@opendash/core";
import { LocationStorageOptions } from "..";

export function useRideSearchEnd() {
  return useStorage(
    "device",
    "ride-sharing:search-end",
    undefined,
    LocationStorageOptions
  );
}
