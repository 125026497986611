import { useObjectState } from "@opendash/core";
import React from "react";

export function useParseQuery(query) {
  const [state, setState, resetState] = useObjectState({
    result: [],
    count: 0,
    loading: true,
    error: undefined,
    reload: fetch,
  });

  async function fetch() {
    if (query) {
      query
        .withCount()
        .find()
        .then(
          (response) => {
            setState({
              result: response.results,
              count: response.count,
              loading: false,
            });
          },
          (error) => {
            setState({ error, loading: false });
          }
        );
    }
  }

  React.useEffect(() => {
    resetState();
    fetch();
  }, [query]);

  return state;
}
