 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";



import Parse from "parse";



export class UserService {
  

   __init() {this.profile = {}}

   __init2() {this.profileCache = {}}

  constructor(kommuter) {;UserService.prototype.__init.call(this);UserService.prototype.__init2.call(this);
    this.kommuter = kommuter;

    makeAutoObservable(this);
  }

   async init() {
    if (Parse.User.current()) {
      await this.fetchCurrentProfile();

      setTimeout(() => {
        this.kommuter.chat.onUser(Parse.User.current());
      }, 0);
    }
  }

   async fetchCurrentProfile() {
    const user = Parse.User.current();

    if (this.kommuter.network.connected) {
      await user.fetchWithInclude(["tenant", "tenant.meta", "rsProfile"]);
    }

    const profile = await this.fetchProfile(Parse.User.current());

    this.profile = profile;
  }

   async fetchProfile(
    user
  ) {
    const profile = _optionalChain([user, 'optionalAccess', _ => _.get, 'call', _2 => _2("rsProfile")]) ;

    const profileJson = _optionalChain([profile, 'optionalAccess', _3 => _3.toJSON, 'call', _4 => _4()]) || {};

    if (_optionalChain([user, 'optionalAccess', _5 => _5.id])) {
      this.profileCache[user.id] = profileJson;
    }

    return profileJson;
  }

   getProfile(user) {
    if (!this.profileCache[_optionalChain([user, 'optionalAccess', _6 => _6.id])] && _optionalChain([user, 'optionalAccess', _7 => _7.id])) {
      const profile = _optionalChain([user, 'optionalAccess', _8 => _8.get, 'call', _9 => _9("rsProfile")]) ;

      const profileJson = _optionalChain([profile, 'optionalAccess', _10 => _10.toJSON, 'call', _11 => _11()]) || {};

      this.profileCache[user.id] = profileJson;
    }

    return this.profileCache[_optionalChain([user, 'optionalAccess', _12 => _12.id])] || {};
  }

   getAvatar(user) {
    const profile = _optionalChain([user, 'optionalAccess', _13 => _13.get, 'call', _14 => _14("rsProfile")]) ;
    const image = _optionalChain([profile, 'optionalAccess', _15 => _15.get, 'call', _16 => _16("image")]);
    const url = image.url();

    return url || undefined;
  }
}
