import Parse from "parse";
import React from "react";
import distance from "@turf/distance";
import {
  createParsePolygonFromRoute,


  Place,
  useParseQuery,
} from "..";









export function usePlacesNearRoute(
  route,
  start,
  end
) {
  const polygon = React.useMemo(
    () => createParsePolygonFromRoute(route),
    [route]
  );

  const query = React.useMemo(
    () =>
      polygon
        ? new Parse.Query(Place)
            .equalTo("enabled", true)
            .limit(1000)
            // @ts-ignore
            .withinPolygon("location", polygon)
        : null,
    [polygon]
  );

  const data = useParseQuery(query);

  return React.useMemo(() => {
    const result = [];
    const distances = {};

    for (const a of data.result) {
      const b = result.find(
        (c) =>
          c.get("name") === a.get("name") && c.get("type") === a.get("type")
      );

      if (b) {
        const dis = distance(
          [a.get("location").latitude, a.get("location").longitude],
          [b.get("location").latitude, b.get("location").longitude],
          {
            units: "meters",
          }
        );

        if (dis < 100) {
          continue;
        }
      }

      result.push(a);
    }

    if (route && start) {
      const routeLine = route.points.coordinates;
      const routeStart = [
        start.get("location").latitude,
        start.get("location").longitude,
      ];

      for (const a of data.result) {
        distances[a.id] = distance(
          routeStart,
          [a.get("location").latitude, a.get("location").longitude],
          {
            units: "meters",
          }
        );
      }

      result.sort((a, b) => distances[a.id] - distances[b.id]);
    }

    return { ...data, result, distances };
  }, [data, route]);
}
