import { useDebounce, usePromise } from "@opendash/core";
import Parse from "parse";
import React from "react";
import { Location, useCurrentPosition } from "..";
import { runParseGeocode } from "../cloud-code";







export function useCloudCodeGeoCoding(query) {
  const queryDebounced = useDebounce(query, 500);
  const [latitude, longitude] = useCurrentPosition();

  const [result, error, loading] = usePromise(
    async () =>
      !queryDebounced
        ? []
        : await runParseGeocode({
            query: queryDebounced,
            latitude,
            longitude,
            limit: 3,
          }),
    [queryDebounced]
  );

  return React.useMemo(() => {
    return {
      error,
      loading,
      result: (result || []).map((result) => {
        return new Location({
          title: result.title,
          description: result.description,
          location: new Parse.GeoPoint(result.location),
          icon: "fa:map-marker-alt",
        });
      }),
    };
  }, [result, error, loading]);
}
