 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";

import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { $framework, } from "@opendash/core";


export class PushService {
  

  constructor(kommuter) {
    this.kommuter = kommuter;

    makeAutoObservable(this);
  }

  async init($user) {
    switch (Capacitor.getPlatform()) {
      case "web":
        return initPushNotificationsWeb($user);

      case "android":
      case "ios":
        return initPushNotificationsNative($user);
    }
  }
}

async function initPushNotificationsWeb($user) {
  await $user.registerWebPush();
}

async function initPushNotificationsNative($user) {
  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    // console.log(
    //   "Push notification received: ",
    //   JSON.stringify(notification, null, 2)
    // );
  });

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      // console.log(
      //   "Push notification action performed",
      //   notification.actionId,
      //   notification.inputValue,
      //   JSON.stringify(notification)
      // );

      if (_optionalChain([notification, 'optionalAccess', _ => _.notification, 'optionalAccess', _2 => _2.data, 'optionalAccess', _3 => _3.navigate])) {
        $framework.router.navigate(notification.notification.data.navigate);
      }
    }
  );

  await PushNotifications.addListener("registration", (token) => {
    $user.handlePushSubscription(`capacitor-${Capacitor.getPlatform()}`, {
      token: token.value,
    });
  });

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", JSON.stringify(err.error, null, 2));
  });

  await registerNotificationsNative();
}

async function registerNotificationsNative() {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
}
