const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\Map.tsx";import React from "react";
import ReactMap from "react-map-gl";
import maplibregl from "maplibre-gl";

import "maplibre-gl/dist/maplibre-gl.css";









export const Map = React.memo(function Map({
  width = "100%",
  height = "400px",
  initialLatitude = 50.867377,
  initialLongitude = 8.026677,
  initialZoom = 8,
  children,
}) {
  const style = React.useMemo(
    () => ({
      width,
      height,
    }),
    [width, height]
  );

  const initialViewState = React.useMemo(
    () => ({
      longitude: initialLongitude,
      latitude: initialLatitude,
      zoom: initialZoom,
    }),
    [initialLatitude, initialLongitude, initialZoom]
  );

  return (
    React.createElement(ReactMap, {
      mapStyle: "https://tiles.kommuter.de/styles/bright/style.json",
      mapLib: maplibregl,
      style: style,
      initialViewState: initialViewState, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}

      , children
    )
  );
});
