const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\NewsUpdateNotification.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Loading, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Col, Divider, Drawer, Result, Row } from "antd";
import Parse from "parse";
import React from "react";
import { News } from "../../parse/News";
import { NewsCategory } from "../../parse/NewsCategory";

import { NewsAccept } from "../../parse/NewsAccept";





const NewsUpdateNotification = ({ children }) => {
  const t = useTranslation();
  const user = Parse.User.current();
  const [loading, setLoading] = React.useState(false);
  const [tenant, setTenant] = React.useState(null);
  const [news, setNews] = React.useState


(null);

  //const tos = $parse.server.config.APP_TOS_URL;
  const tos = "https://kommuter.de/de/datenschutz";

  if (!user) return null;
  const init = async () => {
    const categories = await new Parse.Query(NewsCategory).find();

    let newsToSet = null ;

    for (const category of categories) {
      const latestNews = await new Parse.Query(News)
        .equalTo("category", category)
        .descending("createdAt")
        .equalTo("tenant", user.get("tenant"))
        .first();

      if (!latestNews) continue;
      const hasAccepted = await new Parse.Query(NewsAccept)
        .equalTo("user", user )
        .equalTo("news", latestNews)
        .first();
      //if (!hasAccepted) continue;

      if (
        category.get("priority") >
          (newsToSet !== null ? newsToSet.category.get("priority") : -1) &&
        !hasAccepted
      ) {
        newsToSet = { news: latestNews || null, category };
      }
    }
    if (newsToSet) {
      setNews(newsToSet);
    }
    // const tenant = user.get("tenant");
    // await tenant.fetch();
    // setTenant(tenant);
    setLoading(false);
  };
  React.useEffect(() => {
    setLoading(true);
    init();
  }, []);
  return (
    React.createElement(React.Fragment, null
      , news && (
        React.createElement(Drawer, {
          placement: "bottom",
          height: "100%",
          closable: news.news.get("closable"),
          open: !!news,
          title: _optionalChain([news, 'optionalAccess', _ => _.category, 'access', _2 => _2.get, 'call', _3 => _3("label")]),
          onClose: 
            news.news.get("closable")
              ? () => {
                  const accept = new NewsAccept();
                  accept.set("user", user );
                  accept.set("news", news.news);
                  accept.set("acceptedAt", new Date());
                  accept.save().then((res) => {
                    if (res) {
                      setNews(null);
                      init();
                    }
                  });
                }
              : () => {}
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}

          , !loading && (
            React.createElement(React.Fragment, null
              , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
                , React.createElement(Col, { span: 24, style: { width: "100%", height: "85vh" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
                  , React.createElement(Result, {
                    icon: React.createElement(Icon, { icon: news.news.get("icon") || "fa:info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} ),
                    subTitle: news.news.get("subTitle"),
                    title: news.news.get("title"),
                    extra: 
                      news.news.get("link") ? (
                        React.createElement(Button, {
                          type: "primary",
                          onClick: () => {
                            window.open(news.news.get("link"), "_blank");
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}

                          , news.news.get("linkText")
                        )
                      ) : null
                    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
                  )
                )
              )
              , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}} )

              , React.createElement(Row, { gutter: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
                , news.news.get("declineBtnLabel") && (
                  React.createElement(Col, { span: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
                    , React.createElement(Button, {
                      icon: 
                        React.createElement(Icon, {
                          icon: news.news.get("declineBtnIcon") || "fa:info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
)
                      ,
                      type: "primary",
                      danger: true,
                      style: { width: "100%" },
                      onClick: () => {
                        Parse.User.logOut();
                        window.location.reload();
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}

                      , news.news.get("declineBtnLabel")
                    )
                  )
                )
                , news.news.get("acceptBtnLabel") && (
                  React.createElement(Col, { span: news.news.get("declineBtnLabel") ? 12 : 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
                    , React.createElement(Button, {
                      type: "primary",
                      icon: 
                        React.createElement(Icon, {
                          icon: news.news.get("acceptBtnIcon") || "fa:info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
)
                      ,
                      style: { width: "100%" },
                      onClick: () => {
                        const accept = new NewsAccept();
                        accept.set("user", user );
                        accept.set("news", news.news);
                        accept.set("acceptedAt", new Date());
                        accept.save().then((res) => {
                          if (res) {
                            setNews(null);
                            init();
                          }
                        });
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}

                      , news.news.get("acceptBtnLabel")
                    )
                  )
                )
              )
            )
          )
          , loading && React.createElement(Loading, { message: "...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}} )
        )
      )
      , !news && children
    )
  );
};
export { NewsUpdateNotification };
