import { ChatService } from "./ChatService";
import { GeolocationService } from "./GeolocationService";
import { NetworkService } from "./NetworkService";
import { OfferService } from "./OfferService";
import { PushService } from "./PushService";
import { UserService } from "./UserService";

export class Kommuter {
  
  
  
  
  
  

  constructor() {
    this.geolocation = new GeolocationService(this);
    this.push = new PushService(this);
    this.network = new NetworkService(this);
    this.user = new UserService(this);
    this.chat = new ChatService(this);
    this.offers = new OfferService(this);
  }

   init() {
    this.geolocation.init();
    this.user.init();

    return () => {
      this.geolocation.close();
    };
  }
}
