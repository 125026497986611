 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useCloudCodeGeoCodingReverse, useCurrentPosition } from "..";

export function useCurrentLocation() {
  const [latitude, longitude] = useCurrentPosition();

  const locations = useCloudCodeGeoCodingReverse(
    latitude,
    longitude,
    "fa:location"
  );

  return _optionalChain([locations, 'access', _ => _.result, 'optionalAccess', _2 => _2[0]]);
}
