import Parse from "parse";














export class NewsAccept extends Parse.Object {
  constructor(data) {
    super("RideSharingNewsAccept", data );
  }
}

Parse.Object.registerSubclass("RideSharingNewsAccept", NewsAccept);
