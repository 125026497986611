const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\LinkList.tsx";import { Icon } from "@opendash/icons";
import { Link } from "@opendash/router";
import { Button, List } from "antd";
import React from "react";














const listItemStyle = {
  padding: "8px 0px",
};

const buttonStyle = {
  padding: 0,
  width: 16,
};

export const LinkList = React.memo(function LinkList({
  compact,
  loading,
  rows,
}) {
  return (
    React.createElement(List, {
      size: compact ? "small" : "default",
      loading: loading,
      dataSource: rows,
      renderItem: (row) => {
        if ("onClick" in row) {
          return (
            React.createElement(List.Item, {
              onClick: () => {
                row.onClick();
              },
              style: compact ? listItemStyle : null,
              extra: 
                React.createElement(Button, {
                  type: "link",
                  style: buttonStyle,
                  icon: React.createElement(Icon, { icon: "ai:right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
                )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}

              , React.createElement(List.Item.Meta, {
                title: row.title,
                description: row.description,
                avatar: row.avatar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
              )
            )
          );
        }

        if ("link" in row) {
          return (
            React.createElement(Link, { to: row.link, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
              , React.createElement(List.Item, {
                style: compact ? listItemStyle : null,
                extra: 
                  React.createElement(Button, {
                    type: "link",
                    style: buttonStyle,
                    icon: React.createElement(Icon, { icon: "ai:right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}

                , React.createElement(List.Item.Meta, {
                  title: row.title,
                  description: row.description,
                  avatar: row.avatar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
                )
              )
            )
          );
        }
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
    )
  );
});
