import { makeAutoObservable } from "mobx";

import { $framework } from "@opendash/core";
import Parse from "parse";











export class OfferService {
  

  constructor(kommuter) {
    this.kommuter = kommuter;

    makeAutoObservable(this);
  }

  get requestedOfferId() {
    return $framework.router.searchParams.requestOffer || null;
  }

  requestOffer(payload) {
    if (!payload) {
      $framework.router.setSearchParam("requestOffer", undefined);
    } else {
      $framework.router.setSearchParam(
        "requestOffer",
        JSON.stringify({
          ...payload,
          startLocation: [
            payload.startLocation.latitude,
            payload.startLocation.longitude,
          ],
          endLocation: payload.endLocation
            ? [payload.endLocation.latitude, payload.endLocation.longitude]
            : null,
        })
      );
    }
  }

  parseOfferPayload(payloadString) {
    const payload = JSON.parse(payloadString);

    return {
      offerId: payload.offerId,
      startLocation: new Parse.GeoPoint(
        payload.startLocation 
      ),
      endLocation: payload.endLocation
        ? new Parse.GeoPoint(payload.endLocation )
        : null,
      weekday: payload.weekday,
      time: payload.time,
      date: payload.date,
    };
  }
}
