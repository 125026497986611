const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\GlobalComponent.tsx";import { observer } from "mobx-react-lite";
import React from "react";
import { RideOfferRequest } from "..";

export const GlobalComponent = observer(function GlobalComponent() {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(RideOfferRequest, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 8}} )
    )
  );
});
