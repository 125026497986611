import React from "react";

import { autorun } from "mobx";
import { kommuter } from "..";

export function useCurrentPosition() {
  const [state, setState] = React.useState([0, 0]);

  React.useEffect(() => {
    return autorun(() => {
      setState([kommuter.geolocation.latitude, kommuter.geolocation.longitude]);
    });
  }, []);

  return state;
}
