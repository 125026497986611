import Parse from "parse";


































export class Profile extends Parse.Object {
  constructor(data) {
    super("RideSharingProfile", data );
  }
}

Parse.Object.registerSubclass("RideSharingProfile", Profile);
