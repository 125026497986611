import { useEventListener, useForceRender } from "@opendash/core";
import Parse from "parse";

export function useParseUser() {
  const forceRender = useForceRender();

  useEventListener("storage", ({ key, newValue }) => {
    if (key === "Parse/ride-sharing/currentUser") {
      forceRender();
    }
  });

  return Parse.User.current();
}
