import { useTranslation } from "@opendash/core";
import React from "react";

export const FormatWeekdays = React.memo(({ days }) => {
  const t = useTranslation();

  if (days.length === 7) {
    return t("rs:weekdays.all");
  }

  if ([1, 2, 3, 4, 5].every((x) => days.includes(x))) {
    return t("rs:weekdays.all_work");
  }

  return days.map((day) => t(`rs:weekdays.day_${day}_2`)).join(", ");
});
