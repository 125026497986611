 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";



export class NetworkService {
  

   __init() {this.connected = _nullishCoalesce(_optionalChain([navigator, 'optionalAccess', _2 => _2.onLine]), () => ( true))}
   __init2() {this.connectionType = "unknown"}

   __init3() {this.handler = () => this.setState()}

  constructor(kommuter) {;NetworkService.prototype.__init.call(this);NetworkService.prototype.__init2.call(this);NetworkService.prototype.__init3.call(this);
    this.kommuter = kommuter;

    makeAutoObservable(this);

    this.watch();
  }

   setState() {
    this.connected = _nullishCoalesce(_optionalChain([navigator, 'optionalAccess', _3 => _3.onLine]), () => ( true));
    this.connectionType = "unknown";
  }

   async watch() {
    window.addEventListener("online", this.handler);
    window.addEventListener("offline", this.handler);

    return () => {
      window.removeEventListener("online", this.handler);
      window.removeEventListener("offline", this.handler);
    };
  }
}
