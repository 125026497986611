import Parse from "parse";














export class OfferRoute extends Parse.Object {
  constructor(data) {
    super("RideSharingOfferRoute", data );
  }
}

Parse.Object.registerSubclass("RideSharingOfferRoute", OfferRoute);
