import Parse from "parse";














export class TaxiInfo extends Parse.Object {
  constructor(data) {
    super("RideSharingTaxiInfo", data );
  }
}

Parse.Object.registerSubclass("RideSharingTaxiInfo", TaxiInfo);
