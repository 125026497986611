const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserSettingsLock.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Button, Form, Input } from "antd";
import React from "react";
import styled from "styled-components";
import { MetaIcon, getUserProfile } from "..";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Description = styled.div`
  margin-bottom: 10px;
`;

export function UserSettingsLock({ onUnlock }) {
  const t = useTranslation();

  const [state, setState] = React.useState("");
  const pin = getUserProfile().accessibilitySupervisorPin;

  const correct = _optionalChain([pin, 'optionalAccess', _ => _.toString, 'call', _2 => _2()]) === state;

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , React.createElement(MetaIcon, { icon: "STOPP", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
        , React.createElement(Title, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, t("rs:profile.lock.title"))
        , React.createElement(Description, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, t("rs:profile.lock.description"))

        , React.createElement(Form, {
          onFinish: () => {
            if (correct) {
              onUnlock();
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}

          , React.createElement(Form.Item, {
            hasFeedback: true,
            validateStatus: correct ? "success" : "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}

            , React.createElement(Input, {
              size: "large",
              autoFocus: true,
              type: "password",
              value: state,
              onChange: (e) => {
                setState(e.target.value);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
            )
          )
          , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
            , React.createElement(Button, {
              size: "large",
              type: "primary",
              htmlType: "submit",
              children: t("rs:profile.lock.action"),
              disabled: !correct, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            )
          )
        )
      )
    )
  );
}
