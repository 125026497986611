import Parse from "parse";

























export class News extends Parse.Object {
  constructor(data) {
    super("RideSharingNews", data );
  }
}

Parse.Object.registerSubclass("RideSharingNews", News);
